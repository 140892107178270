import React from 'react'
import {ReactComponent as ArrowTopSVG} from '../svg/icons/fast__top__circle.svg'
import {ReactComponent as ArrowBottomSVG} from '../svg/icons/fast__bottom__circle.svg'
import { motion, AnimatePresence } from 'framer-motion'
import { addContainerVariants } from '../animations/add'


export default function Summary(props) {
    return (
        <div className="summary">
            <div className="summary__title" onClick = {() => {
                props.setSummary(!props.summary)
                props.setAdd(false)
            }}>
                <p>Résumé</p>
                {
                    props.summary ? 
                        <ArrowBottomSVG />
                    : 
                        <ArrowTopSVG />
                }
            </div>
            
            <AnimatePresence exitBeforeEnter initial = {false}>
            {
                props.summary ? 
                    <motion.div 
                        exit = "exit"
                        animate = "animate"
                        initial = "initial"
                        variants = {addContainerVariants}
                        className='summary__content'
                    >
                        <div>
                            <h1>Temps total</h1>
                            <p>
                                {
                                    `
                                    ${props.content.map((element) => {
                                        return props.getInfo(element.idStrategy, "duration")
                                    }).reduce((acc, curr) => acc + curr)} jours, soit ${(props.content.map((element) => {
                                        return props.getInfo(element.idStrategy, "duration")
                                    }).reduce((acc, curr) => acc + curr) / 25 ).toFixed(2)} mois, soit ${(props.content.map((element) => {
                                        return props.getInfo(element.idStrategy, "duration")
                                    }).reduce((acc, curr) => acc + curr) / 300 ).toFixed(2)} ans
                                    `
                                }
                            </p>
                        </div>

                        <div>
                            <h1>Résultat du dernier investissement</h1>
                            <div>
                            <div>
                                <p>Pessimiste</p>
                                <p>
                                    {
                                        (parseFloat(props.content[props.content.length - 1].amount) + (props.content[props.content.length - 1].amount * parseFloat(props.getInfo(props.content[props.content.length - 1].idStrategy, "rateFrom")) / 100 * props.getInfo(props.content[props.content.length - 1].idStrategy, "duration"))).toFixed(2)
                                    } €
                                </p>
                            </div>
                            <div>
                                <p>Optimiste</p>
                                <p>
                                    {
                                        (parseFloat(props.content[props.content.length - 1].amount) + (props.content[props.content.length - 1].amount * parseFloat(props.getInfo(props.content[props.content.length - 1].idStrategy, "rateTo")) / 100 * props.getInfo(props.content[props.content.length - 1].idStrategy, "duration"))).toFixed(2)
                                    } €
                                </p>
                            </div>
                            </div>
                        </div>

                    </motion.div>
                : null
            }
            </AnimatePresence>
        </div>
    )
}