

export const chooseStrategyVariants = {
    exit : {
        height : 0
    }, 
    animate : {
        height : "200px"
    }, 
    initial : {
        height : 0
    }
}


export const addContainerVariants = {
    exit : {
        height : 0
    }, 
    animate : {
        height : "auto"
    }, 
    initial : {
        height : 0
    }
}