import React, {useState} from 'react'
import { quwiexStrategies } from '../datas/quwiexStrategies'
import {ReactComponent as CancelSVG} from "../svg/icons/cancel.svg"
import Add from './Add'
import Summary from './Summary'
import { motion } from 'framer-motion'


export default function Main() {
    const [content, setContent] = useState([
        /*{
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },        {
            id : 0, 
            idStrategy : 1, 
            amount : 30
        }, 
        {
            id : 1, 
            idStrategy : 2, 
            amount : 40
        },*/
    ])
    const [add, setAdd] = useState(false)
    const [summary, setSummary] = useState(false)

    const getInfo = (idStrategy, key) => {
        return quwiexStrategies.find(element => element.id === idStrategy)[key]
    }


    const handleDelete = (id) => {
        setContent(content.filter(element => element.id !== id))
    }

    return (
        <React.Fragment>
        {
            content.length > 0 ?
                <Summary 
                summary = {summary}
                setSummary = {setSummary}
                add = {add}
                setAdd = {setAdd}
                content = {content}
                getInfo = {getInfo}
                />
            : 
            null
        }

        <Add 
            add = {add}
            setAdd = {setAdd}
            setSummary = {setSummary}
            getInfo = {getInfo}
            content = {content}
            setContent = {setContent}
        
        />
        <div className = "homepage">
            <div className = "array">
                <div className = "array__header">
                    <div className='delete'></div>
                    <div>Nom</div>
                    <div>Temps</div>
                    <div>Montant</div>
                    <div>Résultat pessimiste</div>
                    <div>Résultat optimiste</div>
                </div>

                <div className = "array__content">
                    {
                        content.length > 0 ? 
                            content.map(element => (
                                <div key = {JSON.stringify(element)}>
                                    <div className='delete'><motion.div onClick = {() => handleDelete(element.id)} whileTap={{scale : 0.8}}><CancelSVG /></motion.div></div>
                                    <div>{getInfo(element.idStrategy, "title")}</div>
                                    <div>
                                        {
                                            getInfo(element.idStrategy, "duration") > 25 ? 
                                                `${(getInfo(element.idStrategy, "duration") / 25).toFixed(2)} mois` 
                                            : 
                                                `${getInfo(element.idStrategy, "duration")} jours`}
                                    </div>
                                    <div>
                                        {element.amount} €
                                    </div>
                                    <div>
                                        {
                                            (element.amount * parseFloat(getInfo(element.idStrategy, "rateFrom")) / 100 * getInfo(element.idStrategy, "duration")).toFixed(2)
                                        } €
                                    </div>
                                    <div>
                                        {
                                            (element.amount * parseFloat(getInfo(element.idStrategy, "rateTo")) / 100 * getInfo(element.idStrategy, "duration")).toFixed(2)
                                        } €
                                    </div>
                                </div>
                            ))
                        : 
                            <div className='noData'>
                                <p>Cliquez sur ajouter pour démarrer votre simulation</p>
                            </div>
                    }
                </div>

            </div>
        </div>
        </React.Fragment>
    )
}