import React, {useState} from 'react'
import { quwiexStrategies } from '../datas/quwiexStrategies'
import {ReactComponent as ArrowTopSVG} from '../svg/icons/fast__top__circle.svg'
import {ReactComponent as ArrowBottomSVG} from '../svg/icons/fast__bottom__circle.svg'
import {ReactComponent as PasteSVG} from '../svg/icons/paste.svg'
import {v4} from 'uuid'
import {motion, AnimatePresence} from 'framer-motion'
import { addContainerVariants, chooseStrategyVariants } from '../animations/add'

export default function Add(props) {
    const [formContainer, setFormContainer] = useState({
        id : v4(), 
        idStrategy : null, 
        amount : 0
    })
    const handleChooseStrategy = () => {
        setChooseStrategy(!chooseStrategy)
    }

    const handleChangeStrategy = (id) => {
        setFormContainer(state => {
            return {
                ...state, 
                idStrategy : id
            }
        })
        handleChooseStrategy()
    }

    const handleChangeAmount = (e) => {
        setFormContainer(state => {
            return {
                ...state, 
                amount : e.target.value
            }
        })
    }

    const handlePasteLastInvestment = () => {
        const lastInvestment = props.content[props.content.length - 1]
        setFormContainer(state => {
            return {
                ...state, 
                amount : (lastInvestment.amount * parseFloat(props.getInfo(lastInvestment.idStrategy, "rateFrom")) / 100 * props.getInfo(lastInvestment.idStrategy, "duration") + parseFloat(lastInvestment.amount)).toFixed(2) 
            }
        })
        
    }
    //console.log(formContainer)

    const [chooseStrategy, setChooseStrategy] = useState(false)

    const handleAdd = () => {
        if(formContainer.idStrategy !== null && formContainer.amount >= parseFloat(props.getInfo(formContainer.idStrategy, "limitMin")) && formContainer.amount <= parseFloat(props.getInfo(formContainer.idStrategy, "limitMax"))) {

            props.setContent(state => {
                return [
                    ...state, 
                    formContainer
                ]
            })
            props.setAdd(false)
        }

    }




    return (
        <div className="add">
            <div className='add__title' onClick = {() => {
                props.setAdd(!props.add)
                props.setSummary(false)
                setFormContainer({
                    id : v4(), 
                    idStrategy : null, 
                    amount : 0
                })
            }}>
                <p>Ajouter</p>
                {
                    props.add ? 
                        <ArrowBottomSVG />
                    : 
                        <ArrowTopSVG />
                }
                
            </div>
            <AnimatePresence exitBeforeEnter initial = {false}>
            {
                props.add ? 
                    <motion.div 
                        exit = "exit"
                        animate = "animate"
                        initial = "initial"
                        variants = {addContainerVariants}
                        className='add__content'
                    >
                        {
                            formContainer.idStrategy !== null ? 
                                <motion.div 
                                    exit = "exit"
                                    animate = "animate"
                                    initial = "initial"
                                    variants = {addContainerVariants}
                                    className='estimate'>
                                    <div>
                                        <h1>Estimation temps</h1>
                                        <p>

                                                {`${props.getInfo(formContainer.idStrategy, "duration")} jours`}
                                            
                                        </p>

                                    </div>
                                    <div>
                                        <h1>Estimation rendement</h1>
                                        <div>
                                            <div>
                                                <p>Pessimiste</p>
                                                <p>{
                                                    
                                                    (formContainer.amount * parseFloat(props.getInfo(formContainer.idStrategy, "rateFrom")) / 100 * props.getInfo(formContainer.idStrategy, "duration")).toFixed(2)
                                                } €</p>
                                            </div>
                                            <div>
                                                <p>Optimiste</p>
                                                <p>{
                                                    
                                                    (formContainer.amount * parseFloat(props.getInfo(formContainer.idStrategy, "rateTo")) / 100 * props.getInfo(formContainer.idStrategy, "duration")).toFixed(2)
                                                } €</p>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>

                            : null
                        }


                        <div className='selectStrategy'>
                            <p className='label'>Choisir une stratégie</p>
                            <div>
                                <motion.p whileTap = {{scale : 0.8}} onClick={handleChooseStrategy}>{formContainer.idStrategy === null ? "Choisir" : props.getInfo(formContainer.idStrategy, "title") }</motion.p>

                                <AnimatePresence exitBeforeEnter initial = {false}>
                                {
                                    chooseStrategy ? 
                                    <React.Fragment>
                                        <div className='background' onClick={handleChooseStrategy}></div>
                                        <motion.div
                                            exit = "exit"
                                            animate = "animate"
                                            initial = "initial"
                                            variants = {chooseStrategyVariants}
                                        >

                                            {
                                                quwiexStrategies.map(element => (
                                                    <motion.div 
                                                        whileTap = {{scale : 0.8}}
                                                        key = {JSON.stringify(element)}
                                                        onClick = {() => handleChangeStrategy(element.id)}
                                                    >
                                                        {element.title}
                                                    </motion.div>
                                                ))
                                            }
                                        </motion.div>
                                    </React.Fragment>

                                    : null
                                }
                                </AnimatePresence>
                            </div>

                            {
                                    formContainer.idStrategy !== null ? 
                                        <div className='minMax'>
                                            <p>Passimiste : {props.getInfo(formContainer.idStrategy, "rateFrom")}%</p>
                                            <p>Optimiste : {props.getInfo(formContainer.idStrategy, "rateTo")}%</p>
                                        </div>

                                    : 
                                        null
                            }

                        </div>

                        <div className='amount'>
                            <p className='label'>Montant de l'investissement</p>
                            {
                                props.content.length > 0 ? 
                                <div className='paste' onClick = {handlePasteLastInvestment}>
                                    <PasteSVG/>
                                    <p>Coller le résultat du dernier investissement (pessimiste)</p>
                                </div>
                                : 
                                null
                            }


                            <input 
                                type = "number" 
                                step = "0.01"
                                value = {formContainer.amount} 
                                onChange = {(e) => formContainer.idStrategy !== null ? handleChangeAmount(e) : null}
                                min = {
                                    formContainer.idStrategy === null ? 
                                        0
                                    : 
                                        props.getInfo(formContainer.idStrategy, "limitMin")
                                }

                                max = {
                                    formContainer.idStrategy === null ? 
                                        0
                                    : 
                                        props.getInfo(formContainer.idStrategy, "limitMax")
                                }
                                
                            />
                            {
                                formContainer.idStrategy !== null ? 
                                <div className='minMax'>
                                <p>Min : {props.getInfo(formContainer.idStrategy, "limitMin")}€</p>
                                <p>Max : {props.getInfo(formContainer.idStrategy, "limitMax")}€</p>
                            </div>

                                : null
                            }


                        </div>

                        <motion.button 
                            onClick={handleAdd}
                            whileTap = {{scale : 0.8}}
                        >
                            Ajouter
                        </motion.button>

                    </motion.div>
                : null
            }
            </AnimatePresence>

        </div>
    )
}