import Main from './components/Main';

import './styles/style.css';

function App() {

  return (
    <Main />
  );
}

export default App;
