


export const quwiexStrategies = [
    {"id":1,"rate":"1.21","rateFrom":"1.00","rateTo":"1.40","duration":15,"currency":"USD","title":"Kron Light","limitMin":"20","limitMax":"499","noBase":false,"capitalization":false,"dailyBonus":"working_days","typeId":1,"slug":"standart","typeTitle":"Standatr","dailyWithdrawal":false,"allowedReinvest":true,"nextPeriod":null,"maxActiveDeposits":1,"color":"#FFAF00","activeDeposits":false},
    {"id":2,"rate":"1.51","rateFrom":"1.40","rateTo":"1.70","duration":25,"currency":"USD","title":"Kron Max","limitMin":"500","limitMax":"2999","noBase":false,"capitalization":false,"dailyBonus":"working_days","typeId":1,"slug":"standart","typeTitle":"Standatr","dailyWithdrawal":false,"allowedReinvest":true,"nextPeriod":null,"maxActiveDeposits":null,"color":"#FFAF00"},
    {"id":3,"rate":"1.92","rateFrom":"1.70","rateTo":"2.10","duration":35,"currency":"USD","title":"Kron Pro","limitMin":"3000","limitMax":"14999","noBase":false,"capitalization":false,"dailyBonus":"working_days","typeId":1,"slug":"standart","typeTitle":"Standatr","dailyWithdrawal":false,"allowedReinvest":true,"nextPeriod":null,"maxActiveDeposits":null,"color":"#FFAF00"},
    {"id":4,"rate":"2.14","rateFrom":"1.90","rateTo":"2.30","duration":45,"currency":"USD","title":"Evr Light","limitMin":"15000","limitMax":"49999","noBase":false,"capitalization":false,"dailyBonus":"working_days","typeId":1,"slug":"standart","typeTitle":"Standatr","dailyWithdrawal":false,"allowedReinvest":true,"nextPeriod":null,"maxActiveDeposits":null,"color":"#FFAF00"},
    {"id":5,"rate":"2.35","rateFrom":"2.10","rateTo":"2.60","duration":55,"currency":"USD","title":"Evr Max","limitMin":"50000","limitMax":"249999","noBase":false,"capitalization":false,"dailyBonus":"working_days","typeId":1,"slug":"standart","typeTitle":"Standatr","dailyWithdrawal":false,"allowedReinvest":true,"nextPeriod":null,"maxActiveDeposits":null,"color":"#FFAF00"},
    {"id":6,"rate":"2.80","rateFrom":"2.40","rateTo":"3.00","duration":65,"currency":"USD","title":"Evr Pro","limitMin":"250000","limitMax":"1000000","noBase":false,"capitalization":false,"dailyBonus":"working_days","typeId":1,"slug":"standart","typeTitle":"Standatr","dailyWithdrawal":false,"allowedReinvest":true,"nextPeriod":null,"maxActiveDeposits":null,"color":"#FFAF00"},
    {"id":28,"rate":"4.00","rateFrom":"4.00","rateTo":"4.00","duration":180,"currency":"USD","title":"Fort Max","limitMin":"20","limitMax":"49999","noBase":false,"capitalization":false,"dailyBonus":"working_days","typeId":3,"slug":"standart_without_core_and_profit","typeTitle":"STANDARD-WITHOUT-PROFIT","dailyWithdrawal":true,"allowedReinvest":true,"nextPeriod":null,"maxActiveDeposits":null,"color":"#B15BF0"},
    {"id":29,"rate":"5.00","rateFrom":"5.00","rateTo":"5.00","duration":180,"currency":"USD","title":"Fort Pro","limitMin":"50000","limitMax":"1000000","noBase":false,"capitalization":false,"dailyBonus":"working_days","typeId":3,"slug":"standart_without_core_and_profit","typeTitle":"STANDARD-WITHOUT-PROFIT","dailyWithdrawal":true,"allowedReinvest":true,"nextPeriod":null,"maxActiveDeposits":null,"color":"#B15BF0"}
]

